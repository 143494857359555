import React, { useEffect, useState } from "react";
import { setCurrentRoute } from "Store/features/currentRoute";
import { useDispatch } from "react-redux";
import upcomingColumnDef from "./upcomingColumnDef";
import { useSelector } from "react-redux";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import UserCard from "Pages/ManagersFlow/Components/UserCard/UserCard";
import searchIcon from "Components/NewDashboard/Assets/searchIcon.svg";
import CompanyCard from "Pages/ManagersFlow/Components/CompanyCard/CompanyCard";
import LeaderBoard from "Components/NewDashboard/LeaderBoard/LeaderBoard";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import DonutChartSmall from "Pages/Meeting/Components/DonutChart/DonutChartSmall";
import capsuleIcon from "../../Assets/capsuleIcon.svg";
import medicineIcon from "../../../../Assets/medicineIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import styles from "./home.module.css";
import Tabs from "Components/NewDashboard/Tabs/Tabs";
import DataTable from "Components/Common/DataTable/DataTable";
import {
  baseUrl,
  getAllTeamStatsByManager,
  getMeetingsByManager,
  getProductStats,
  getRepStatsByManager,
  getTeamStatsByManager,
  recordingTable,
} from "Api/Backend/backend";
import ScheduleMeetingModal from "Components/SalesRep/ScheduleMeetingForm/ScheduleMeetingModal";
import {
  allMeetings,
  completedMeetings,
  scheduleCount,
} from "Store/features/scheduleMeetingSlice";
import moment from "moment";
import axios from "axios";
import SalesSplitView from "Pages/ManagersFlow/Components/SalesSplitView/SalesSplitView";
import SalesRepLeaderBoard from "Components/NewDashboard/SalesrepLeaderBoard/SalesRepLeaderBoard";
import { Chip, Grid } from "@mui/material";
import MeetingResult from "Components/NewDashboard/MeetingResult/MeetingResult";

const ManagersHome = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.setUser.user);
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [meetingData, setMeetingData] = useState(0);
  const [title, setTitle] = useState("");
  const [isPastMeeting, setIsPastMeeting] = useState(false);
  const [isFocus, setIsFocus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [scheduledMeetings, setAllScheduledMeetings] = useState([]);
  const [count, setCount] = useState(0);
  const [notificationTab, setNotificationTab] = useState("new");

  const [tableData, setTableData] = useState([]);
  const [scheduled, setScheduled] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [allTeamData, setAllTeamData] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [productData, setProductData] = useState(null);
  const [repsData, setRepsData] = useState([]);
  const userReducer = useSelector((state) => state.setUser.user);
  const { user_id } = userReducer;

  const [value, setValue] = useState(0);

  function getCurrentTime() {
    const date = new Date();
    const currentTime = date.toLocaleString();
    return currentTime;
  }

  function getTimeOnly() {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();

    return time;
  }
  function getDateOn() {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    return formattedDate;
  }
  const onRowClick = (data) => {
    console.log(data);
    setMeetingData(data);
    setIsOpen(true);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const [activeTab, setActiveTab] = useState("client"); // State to track active tab

  const handleTab = (tab) => {
    setActiveTab(tab);
  };
  const handleNotificationTab = (tab) => {
    setNotificationTab(tab);
    // Add logic here to handle tab change if needed
  };

  const getTableData = async (id) => {
    const response = await recordingTable(id)
      .then((response) => {
        setTableData(response);
      })
      .catch((e) => console.log(e));
  };

  dispatch(setCurrentRoute(0));
  const barBg = (perc) => {
    if (perc > 0 && perc < 30) {
      return "#E51A3F";
    } else if (perc >= 30 && perc < 50) {
      return "#F3C243";
    } else if (perc >= 50) {
      return "#28CA90 ";
    }
  };
  const getTeamData = (id) => {
    const response = getTeamStatsByManager(id)
      .then((res) => {
        console.log(res, "team stats");
        setTeamData(res);
      })
      .catch((e) => {
        console.log(e);
      });
    const response2 = getAllTeamStatsByManager(id)
      .then((res) => {
        setAllTeamData(res);
      })
      .catch((e) => {
        console.log(e);
      });
    const response3 = getProductStats(id)
      .then((res) => {
        setProductData(res);
      })
      .catch((e) => {
        console.log(e);
      });
    const response4 = getRepStatsByManager(id)
      .then((res) => {
        console.log(res);
        setRepsData(res);
      })
      .catch((e) => console.log(e));
  };
  function getCurrentTime() {
    const date = new Date();
    const currentTime = date.toLocaleString();
    return currentTime;
  }
  function getTimeOnly(dateString) {
    let now = moment();
    let time = now.hour() + ":" + now.minutes() + ":" + now.seconds();
    return time;
  }
  function getDateOn() {
    const currentDate = moment();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    return formattedDate;
  }
  const getMeetings = (id) => {
    const time = getCurrentTime();
    const todayTime = getTimeOnly(time);
    const todayDate = getDateOn();
    const current_time = `${todayDate + " " + todayTime}`;
    const response = getMeetingsByManager(id, current_time)
      .then((res) => {
        setMeetings(res);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      // getTableData(user.user_id);
      getTeamData(user.user_id);
      getMeetings(user.user_id);
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.menuSection}>
        <UserCard
          title={`${currentUser.first_name} ${currentUser.last_name}`}
          subTitle={"Manager"}
          icon={currentUser.user_image}
        />
        {allTeamData && (
          <CompanyCard
            teamMembers={repsData.length}
            teamName={allTeamData[0].team_name}
          />
        )}
        {teamData && (
          <LeaderBoard
            totalEngagements={teamData.number_of_engagements}
            position={meetings.length}
            successScore={Math.round(teamData.avg_success_score)}
            isManager={true}
          />
        )}
      </div>
      <div className={styles.settingSection}>
        <div className={styles.sectionHeaderContainer}>
          <div className={styles.sectionHeader}>Top Insights</div>
          {/* <div className={styles.dateContainer}>
            <div className={styles.dateText}>Oct 01 - Oct 24</div>
            <div className={styles.dateSubtitle}>Monthly</div>
          </div> */}
        </div>
        <div className={styles.statisticsInnerContainer}>
          <div className={styles.stats}>
            <h3>Average Affect Score</h3>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                width: "100%",
              }}
            >
              {teamData && (
                <DonutChartSmall
                  width={"50%"}
                  value={Math.round(teamData.avg_success_score)}
                  x={40}
                  cutout={"90%"}
                  fontSize={"22px"}
                  y={-2.5}
                  barColor={barBg(Math.round(teamData.avg_success_score))}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(238, 104, 128, 1)",
                fontSize: "12px",
                wordBreak: "break-all",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              8 Reps under 40% average success score
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Engagements</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {teamData && (
                <div className={styles.statsValueNumber}>
                  {teamData.number_of_engagements}
                </div>
              )}
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(255, 255, 255, 0.50)",
                fontSize: "12px",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              More than 700 calls with over 50% success score
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Top Performing REP</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {repsData.length > 0 && (
                <div className={styles.statsValue}>
                  {repsData[0].first_name} {repsData[0].last_name}
                </div>
              )}
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "rgba(255, 255, 255, 0.80)",
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              {repsData.length > 0 && `${repsData[0].engagements} Interactions`}
              <br />
              <span style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}>
                {" "}
                {repsData.length > 0 &&
                  `${Math.round(repsData[0].avg_success_score)}% Success Score`}
              </span>
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Least Performing REP</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {repsData.length && (
                <div className={styles.statsValue}>
                  {repsData[repsData.length - 1].first_name}{" "}
                  {repsData[repsData.length - 1].last_name}
                </div>
              )}
              <ArrowDownwardRounded
                fontSize="small"
                style={{
                  color: "#E51A3F",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#E51A3F3D",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "#EE6880",
                fontSize: "14px",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              {repsData.length > 0 &&
                `${repsData[repsData.length - 1].engagements} Interactions`}
              <br />
              <span style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}>
                {" "}
                {repsData.length > 0 &&
                  repsData[repsData.length - 1].avg_success_score}
                % Success Score
              </span>
            </div>
          </div>
          <div className={styles.stats}>
            <h3>Best Product</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {productData && (
                <div className={styles.statsValue}>
                  {productData[0].med_name}
                </div>
              )}
              <ArrowUpwardRounded
                fontSize="small"
                style={{
                  color: "#28CA90",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#28CA903D",
                }}
              />
            </div>
            {productData && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  color: "rgba(255, 255, 255, 0.50)",
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                {" "}
                <span
                  style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}
                >
                  {" "}
                  {`Avg Success ${Math.round(
                    productData[0].avg_success_score
                  )}% Score`}{" "}
                </span>
              </div>
              // title={`${Math.round(productData[0].avg_success_score)}%`}
              // subTitle={"Avg Success Score"}
            )}
          </div>
          <div className={styles.stats}>
            <h3>Worst Product</h3>
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              {productData && (
                <div className={styles.statsValue}>
                  {productData[1].med_name.toLowerCase()}
                </div>
              )}
              <ArrowDownwardRounded
                fontSize="small"
                style={{
                  color: "#E51A3F",
                  borderRadius: "20px",
                  marginLeft: "5px",
                  padding: "1px",
                  background: "#E51A3F3D",
                }}
              />
            </div>
            {productData && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "80%",
                  color: "rgba(255, 255, 255, 0.50)",
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "left",
                }}
              >
                {" "}
                <span
                  style={{ fontSize: "12px", fontWeight: 500, opacity: 0.8 }}
                >
                  {" "}
                  {`Avg Success ${Math.round(
                    productData[1].avg_success_score
                  )}% Score`}{" "}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.sectionHeaderContainer}>
          <div className={styles.sectionHeader}> Leaderboard</div>
        </div>
        <Grid container mt={4}>
          <Grid item>
            <Chip
              label="CLIENTS"
              style={{
                color:
                  activeTab === "client"
                    ? "white"
                    : "rgba(255, 255, 255, 0.50)",
                background:
                  activeTab === "client"
                    ? "#9747FF"
                    : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={() => handleTab("client")}
            />
          </Grid>
          <Grid item style={{ marginLeft: "15px" }}>
            <Chip
              label="REPS"
              style={{
                color:
                  activeTab === "reps" ? "white" : "rgba(255, 255, 255, 0.50)",
                background:
                  activeTab === "reps"
                    ? "#9747FF"
                    : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={() => handleTab("reps")}
            />
          </Grid>
          <Grid item style={{ marginLeft: "15px" }}>
            <Chip
              label="PRODUCTS"
              style={{
                color:
                  activeTab === "products"
                    ? "white"
                    : "rgba(255, 255, 255, 0.50)",
                background:
                  activeTab === "products"
                    ? "#9747FF"
                    : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={() => handleTab("products")}
            />
          </Grid>
        </Grid>

        <div className={styles.flexRow}>
          <div
            style={{ marginTop: "3%" }}
            className={styles.searchBarContainer}
          >
            <img src={searchIcon} alt="icon" />
            <input
              className={styles.searchInput}
              onChange={handleChange}
              placeholder="Search here..."
            />
          </div>

          {/* <button
            className={styles.scheduleButton}
            onClick={() => setMeetingModalOpen(true)}
          >
            {" "}
            <AddIcon
              className={styles.plusIcon}
              sx={{ height: "20px", width: "20px" }}
            />
            Schedule Meeting
          </button> */}
          {/* <button
            className={styles.viewEngagementButton}
            onClick={() => setMeetingModalOpen(true)}
          >
            {" "}
            <AddIcon
              className={styles.plusIcon}
              sx={{ height: "20px", width: "20px" }}
            />
            View All Engagements
          </button> */}
        </div>

        {/* <Tabs
          firstTab={"This Week"}
          secondTab={"Next Week"}
          thirdTab={"Ready For Review"}
          value={value}
          setValue={setValue}
        />
        {value === 0 && (
          <div className={styles.dataTableContainer}>
            <DataTable
              isAllInteraction={true}
              isOnStatistics={true}
              variant={"filter"}
              defination={upcomingColumnDef}
              cellData={meetings}
              id={currentUser.user_id}
              setCount={setCount}
            />
          </div>
        )} */}
        <div className={styles.topRightContainer}>
          {activeTab === "client" || activeTab === "reps" ? (
            <div className={styles.leaderBoard}>
              {repsData.length > 0 &&
                repsData.map((data, index) => (
                  <SalesRepLeaderBoard
                    index={index}
                    subTitle={`${data.engagements} Meetings`}
                    name={`${data.first_name} ${data.last_name}`}
                    icon={data.user_image}
                    value={Math.round(data.avg_success_score)}
                  />
                ))}
            </div>
          ) : (
            <div className={styles.topClients}>
              {productData &&
                productData.map((data, index) => (
                  <SalesRepLeaderBoard
                    index={index}
                    subTitle={``}
                    name={`${data.med_name}`}
                    icon={medicineIcon}
                    value={Math.round(data.avg_success_score)}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.notificationSection}>
        <div className={styles.sectionHeaderContainer}>
          <div className={styles.sectionHeader}>Notifications</div>
        </div>
        <Grid container mt={4}>
          <Grid item>
            <Chip
              label="NEWS"
              // className={styles.chip}
              style={{
                color:
                  notificationTab === "new"
                    ? "white"
                    : "rgba(255, 255, 255, 0.50)",
                background:
                  notificationTab === "new"
                    ? "#9747FF"
                    : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={() => handleNotificationTab("new")}
            />
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Chip
              // className={styles.chip}
              label="TO DO"
              style={{
                color:
                  notificationTab === "to-do"
                    ? "white"
                    : "rgba(255, 255, 255, 0.50)",
                background:
                  notificationTab === "to-do"
                    ? "#9747FF"
                    : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={() => handleNotificationTab("to-do")}
            />
          </Grid>
          <Grid item style={{ marginLeft: "10px" }}>
            <Chip
              // className={styles.chip}
              label="IMPORTANT"
              style={{
                color:
                  notificationTab === "important"
                    ? "white"
                    : "rgba(255, 255, 255, 0.50)",
                background:
                  notificationTab === "important"
                    ? "#9747FF"
                    : "var(--dark-inverted-bg-4, rgba(255, 255, 255, 0.04))",
                cursor: "pointer",
                borderRadius: "20px",
              }}
              onClick={() => handleNotificationTab("important")}
            />
          </Grid>
        </Grid>
        <div className={styles.statisticsInnerContainer}>
          <div className={styles.notification}>
            <MeetingResult width={"fit-content"} type={0} title={"New"} />
            <h3>REPS</h3>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "80%",
                color: "#fff",
                fontFamily: "Satoshi Variable",
                fontSize: "13px",
                wordBreak: "break-all",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              Dive, new rep added to team
            </div>
          </div>
        </div>

        {/* <Tabs
          firstTab={"This Week"}
          secondTab={"Next Week"}
          thirdTab={"Ready For Review"}
          value={value}
          setValue={setValue}
        />
        {value === 0 && (
          <div className={styles.dataTableContainer}>
            <DataTable
              isAllInteraction={true}
              isOnStatistics={true}
              variant={"filter"}
              defination={upcomingColumnDef}
              cellData={meetings}
              id={currentUser.user_id}
              setCount={setCount}
            />
          </div>
        )} */}
      </div>
      {/* <ScheduleMeetingModal
        meetings={meetings}
        getAllMeetings={getMeetings}
        setAllScheduledMeetings={setAllScheduledMeetings}
        scheduledMeetings={scheduledMeetings}
        setScheduled={setScheduled}
        meetingModalOpen={meetingModalOpen}
        setMeetingModalOpen={setMeetingModalOpen}
        id={currentUser.user_id}
      /> */}
      {/* {meetingData != 0 && (
        <SalesSplitView
          setIsFocus={setIsFocus}
          setIsOpen={setIsOpen}
          setTitle={setTitle}
          setSourceImage={setSourceImage}
          isViewerOpen={isViewerOpen}
          setIsViewerOpen={setIsViewerOpen}
          isPastMeeting={isPastMeeting}
          meetingData={meetingData}
          isOpen={isOpen}
        />
      )} */}
    </div>
  );
};

export default ManagersHome;
