import React, { useEffect, useRef, useState } from "react";
import styles from "./ScheduleMeetingModal.module.css";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import AddProductForm from "./AddProductForm";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  addProducts,
  baseUrl,
  createMeeting,
  getZoomToken,
} from "Api/Backend/backend";
import { toast } from "react-toastify";
import moment from "moment";
import { ClickAwayListener } from "@mui/base";
import { Modal } from "@mui/material";
import closeIcon from "../../NewDashboard/Assets/closeIcon.svg";
import emailjs from "@emailjs/browser";
import McqQuestion from "Components/NewDashboard/ChoiceComponent/ChoiceComponent";

const AddProductModal = ({ meetingModalOpen, setMeetingModalOpen, id }) => {
  const [meetingTitle, setMeetingTitle] = useState("");
  const [description, setDescription] = useState("");
  const [doc, setDoc] = useState([]);
  const buttonRef = useRef();

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        buttonRef.current.click();
      }
    };
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const handleClose = () => {
    setMeetingModalOpen(false);
  };
  const handleClickAway = (e) => {
    e.stopPropagation();
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    borderRadius: "12px",
    background: "var(--theme-dark-0, #222224)",
    border: "2px solid #000",
    boxShadow: 24,
    maxHeight: "95vh",
    p: 4,
    overflowY: "scroll",
  };
  const addProduct = () => {
    const data = {
      med_name: meetingTitle,
      med_description: description,
      med_image: "",
      med_dosage_administration: `{"ins_1": "over dosage 500mg" , "ins_2": "over dosage 500mg"}`,
      manager_id: 2,
      med_adverse_reaction: "",
      med_drug_interaction: "",
      med_indication_usage: "",
      med_warning_precautions: "",
    };
    const response = addProducts(data)
      .then((res) => {
        toast.success("Product Added");
      })
      .catch((e) => console.log(e));
  };
  return (
    <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
      <Modal
        open={meetingModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div className={styles.headerContainer}>
            <div className={styles.dialogueTitle}>Add Product</div>
            <img
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              src={closeIcon}
              alt="close icon"
            />
          </div>
          <div style={{ padding: "40px" }}>
            <div className={styles.sectionHeader}>Name</div>
            <AddProductForm
              setTitle={setMeetingTitle}
              setDescription={setDescription}
              setDoc={setDoc}
              doc={doc}
              userid={id}
            />
          </div>
          <div className={styles.buttonContainer}>
            <button onClick={handleClose} className={styles.cancelButton}>
              Cancel
            </button>
            <button onClick={addProduct} className={styles.createButton}>
              Add Product
            </button>
          </div>
        </div>
      </Modal>
    </ClickAwayListener>
  );
};

export default AddProductModal;
AddProductModal.defaultProps = {
  getAllMeetings: () => {},
  meetings: "",
  scheduledMeetings: [],
  setAllScheduledMeetings: () => {},
};
