import React, { useState } from "react";
import ReactQuill from "react-quill";
import styles from "./TextEditor.module.css";
import Button from "@mui/material/Button";

const TextEditor = ({
  onSave,
  onCancel,
  initialContent,
  props,
  isSideBar,
  setEditText,
}) => {
  const [content, setContent] = useState(initialContent || "");
  const [editState, setEdit] = useState(true);

  const handleSave = () => {
    onSave(content);
    setEdit(true);
  };

  const handleEdit = () => {
    setEdit(false);
  };

  const handleCancel = () => {
    setEdit(true);
    setEditText(false);
  };
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ];

  return (
    <div
      style={{
        background: "#171618",
        border: "1px solid #AFADB0",
        borderRadius: "4px",
      }}
    >
      <ReactQuill
        style={{ color: "white" }}
        readOnly={editState}
        value={content}
        onChange={setContent}
        modules={{ toolbar: toolbarOptions }}
      />
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <div
          style={{
            margin: "10px",
            width: "30%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isSideBar ? (
            <Button className={styles.cancelButton} onClick={handleCancel}>
              Cancel
            </Button>
          ) : (
            <Button
              disabled={editState}
              className={styles.cancelButton}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          )}
          {editState ? (
            <Button className={styles.editButton} onClick={handleEdit}>
              Edit
            </Button>
          ) : (
            <Button className={styles.scheduleButton} onClick={handleSave}>
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
TextEditor.defaultProps = {
  setEditText: () => {},
  isSideBar: false,
};
export default TextEditor;
