import React, { useState } from 'react';

const StackGraph = ({graphData})=> {

 
    const [tooltipData, setTooltipData] = useState(null);
    const [showLines, setShowLines] = useState({
        score: true,
        score2: true,
        score3: true,
        score4: true,
      });

      {console.log(graphData)}

      if (graphData.status_code === 404 || Object.keys(graphData).length === 0 && graphData.constructor === Object) {
        return <div style={{color:"grey"}}>scores list not found</div>;
      }

      function convertSecondsToMinutesAndSeconds(totalSeconds) {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        if (seconds == 0) {
          return `${minutes}:${seconds}0`;
        } else {
          return `${minutes}:${seconds}`;
        }
      }
    
    // Extracting scores into separate arrays
    const aeScores = graphData.length > 0 && graphData.map(entry => ({
        score: entry.ae_score,
        time: entry.time
    }));
    
    const miScores = graphData.length > 0 && graphData.map(entry =>({
        score: entry.mi_score,
        time: entry.time
    }));
    const viScores = graphData.length > 0 && graphData.map(entry => ({
        score: entry.vi_score,
        time: entry.time
    }));
    const totalScores =graphData.length > 0 && graphData.map(entry => ({
        score: entry.score,
        time: entry.time
    }));

    // Function to determine color based on score value
    const getColor = (value) => {
      if (value <= 30) {
        return 'red';
      } else if (value > 30 && value <= 50) {
        return 'grey';
      } else {
        return 'green';
      }
    };

    // Render bars for each score array
    const renderBars = (scores,text) => {
      return (
        <div style={{ display: 'flex', marginBottom: '20px' }}>
        {scores.map((s, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              width: `${s.score}%`,
              backgroundColor: getColor(s.score),
              height: '30px',
              marginRight: '5px',
              transition: 'width 0.5s ease-in-out',
            }}
            onClick={() => setTooltipData({ index, color: getColor(s.score),text })}
            onMouseEnter={() => setTooltipData({ index,color: getColor(s.score),text })}
            onMouseLeave={() => setTooltipData(null)}
          >
            {tooltipData && tooltipData.index === index && tooltipData.text === text && (
              <div
                style={{
                  position: 'absolute',
                  top: '-20px',
                  left: '50%',
                  width:"250px",
                  backgroundColor: 'white',
                  color: tooltipData.color,
                  padding: '15px',
                  borderRadius: '5px',
                  zIndex: '1',
                }}
              >
                <div>{text} : {s.score} </div>  
                <div>Time :{convertSecondsToMinutesAndSeconds(s.time)}</div>
               
              </div>
            )}
          </div>
        ))}
      </div>
      );
    };

    const toggleLineVisibility = (lineKey) => {
        setShowLines((prevState) => ({
          ...prevState,
          [lineKey]: !prevState[lineKey],
        }));
      };

    return (
      <div style={{ width: '100%', margin: 'auto' }}>
        <>
        
      <div>
        <input
          type="checkbox"
          id="score"
          checked={showLines.score}
          onChange={() => toggleLineVisibility("score")}
        />
        <label htmlFor="score" style={{ color: "green" }}>
          Affect Score
        </label>
        <input
          type="checkbox"
          id="score2"
          checked={showLines.score2}
          onChange={() => toggleLineVisibility("score2")}
        />
        <label htmlFor="score2" style={{ color: "orange" }}>
          Attention Economics
        </label>
        <input
          type="checkbox"
          id="score3"
          checked={showLines.score3}
          onChange={() => toggleLineVisibility("score3")}
        />
        <label htmlFor="score3" style={{ color: "red" }}>
          Mood Induction
        </label>
        <input
          type="checkbox"
          id="score4"
          checked={showLines.score4}
          onChange={() => toggleLineVisibility("score4")}
        />
        <label htmlFor="score4" style={{ color: "blue" }}>
          Value Internalization
        </label>
      </div>
        </>
        <div style={{marginTop:"10px"}}>
        {showLines.score && <div>
          {renderBars(totalScores,"Affect Score")}
        </div>}
        {showLines.score2 && <div>
          {renderBars(aeScores,"Attention Economics")}
        </div>}
        {showLines.score3 && <div>
          {renderBars(miScores,"Mood Induction")}
        </div>}
        {showLines.score4 &&<div>
          {renderBars(viScores,"Value Internalization")}
        </div>}
        </div>
      </div>
    );
  }


export default StackGraph;
