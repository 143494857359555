import React, { useState, useEffect } from "react";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import "./App.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { updatePostMeeting } from "Api/Backend/backend";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "Api/Backend/backend";

function App({
  zoomMeetingLink,
  zoomToken,
  meetingTitle,
  doctorName,
  mid,
  note,
  setIsBotCohost,
  doc_list,
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.setUser.user);
  const meetingInfo = useSelector((state) => state.scheduleMeeting.meeting);
  const meetingStats = useSelector((state) => state.endMeetingStats);
  const client = ZoomMtgEmbedded.createClient();
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState("");
  useEffect(() => {
    if (!startTime) {
      setStartTime(new Date());
    }
  }, []);

  // setup your Meeting SDK auth endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  let authEndpoint = "https://jwt-auth.affectcx.io";
  // This sample app has been updated to use Meeting SDK credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  let sdkKey = "yPORQiY8SdekbbOs5vtJ6Q";
  let meetingNumber = zoomMeetingLink;
  let passWord = "";
  let role = 0;
  let userName = `${currentUser.first_name} ${currentUser.last_name}`;
  let userEmail = currentUser.email;
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/component-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/component-view/webinars#join-registered
  let registrantToken = "";
  let zakToken = zoomToken;
  let startBot = `https://zoombot.affectcx.io/run-zoom-meeting-sdk?meeting_id=${zoomMeetingLink}`;
  let callCount = 1;

  const updateMeetingStatus = async () => {
    const resp = await axios
      .post(`${baseUrl}update_meeting_status/`, {
        mid: meetingInfo[0].mid,
        meeting_status: "inprogress",
      })
      .then((resp) => {
        navigate("/meeting", {
          state: {
            link: zoomMeetingLink,
            zoomToken: zoomToken,
            title: meetingTitle,
            mid: mid,
            note: note,
            doc_list: doc_list,
            doctor_name: doctorName,
          },
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const callBot = () => {
    fetch(startBot, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then((res) => updateMeetingStatus());
  };

  function getSignature() {
    // e.preventDefault();

    fetch(authEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
        if (callCount === 1) {
          callBot();
          callCount += 1;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function startMeeting(signature) {
    let meetingSDKElement = document.getElementById("meetingSDKElement");

    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      // autoRecording: "cloud", // Set auto-recording to "cloud" to enable cloud recording
      // recordingDisplayMode: "custom", // Set recording display mode to "custom"
      customize: {
        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
        toolbar: {
          buttons: [
            {
              text: "Custom Button",
              className: "CustomButton",
              onClick: () => {},
            },
          ],
        },
        video: {
          isResizable: false,
          viewSizes: {
            default: {
              width: meetingSDKElement.clientWidth,
              height: meetingSDKElement.clientHeight + 100,
            },
            ribbon: {
              width: meetingSDKElement.clientWidth,
              height: meetingSDKElement.clientHeight + 100,
            },
          },
        },
      },
    });

    client.endMeeting();
    // .then((res)=>{
    //   navigate("/destroy_meeting")
    // })

    client.join({
      signature: signature,
      sdkKey: sdkKey,
      meetingNumber: meetingNumber,
      password: passWord,
      userName: userName,
      userEmail: userEmail,
      tk: registrantToken,
      zak: zakToken,
    });
  }

  React.useEffect(() => {
    getSignature();
  }, []);

  const updateMeeting = async () => {
    const response = await updatePostMeeting(
      {
        ttlr_user: 0,
        ttlr_dr: 0,
        ae_score: meetingStats.ae_score,
        mi_score: meetingStats.mi_score,
        vi_score: meetingStats.vi_score,
        ae_score_list: meetingStats.ae_score_list.toString(),
        mi_score_list: meetingStats.mi_score_list.toString(),
        vi_score_list: meetingStats.vi_score_list.toString(),
        success_score: meetingStats.success_score,
        video_link: "",
        transcript: "",
        meeting_feedback: "string",
        review_status: "string",
        acceptance_status: "string",
        audio_stream_link: "string",
        actual_start_time: startTime,
        actual_end_time: new Date(),
        meeting_status: "completed",
        dots: "Eren yeager",
      },
      mid
    )
      .then((response) => {
        navigate("/destroy_meeting", { state: mid });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const WebSocket = window.WebSocket;
  const useSocket1 = new WebSocket(
    "wss://microservices.affectcx.io/master/ws/leavemeeting"
  );
  const scoreSocket = new WebSocket(
    "wss://microservices.affectcx.io/master/ws/cohost"
  );
  scoreSocket.onopen = (res) => {
    console.log(res, "cohost");
  };
  scoreSocket.onmessage = (res) => {
    setIsBotCohost(true);
  };
  function onSocketOpen() {
    console.log("WebSocket connection opened successfully!");

    // Your custom function or code here
    // For example, you can call another function or perform some action
  }
  useSocket1.onopen = onSocketOpen;
  useSocket1.onmessage = (event) => {
    console.log(event);
    updateMeeting();
  };

  return (
    <div className="zoomApp" id="zoom">
      {/* For Component View */}
      <div style={{ width: "100%" }} id="meetingSDKElement">
        {/* Zoom Meeting SDK Component View Rendered Here */}
      </div>

      {/* <button  onClick={() =>client.leaveMeeting}>Join Meeting</button> */}
    </div>
  );
}

export default App;
