import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import noProductIcon from "../../Assets/noProductIcon.svg";
import typeMedicine from "../../Assets/typeMedicine.svg";
import styles from "./products.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Tabs from "Components/NewDashboard/Tabs/Tabs";
import ProductTemplate from "Components/NewDashboard/ProductTemplate/ProductTemplate";
import SearchField from "Components/Common/SearchField/SearchField";
import DocumentTab from "Components/SalesRep/DocumentTab/DocumentTab";
import {
  assignMedtoTeam,
  assignUsers,
  baseUrl,
  getAssignedTeams,
  getAssignedUsers,
  getProducts,
  getUnAssignedTeams,
  getUnAssignedUsers,
} from "Api/Backend/backend";
import axios from "axios";
import DeleteFilesModal from "Pages/SalesRep/Library/DeleteFilesModal";
import ProductSplitView from "Components/NewDashboard/ProductSplit/ProductSplitView";
import { toast } from "react-toastify";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import InfoCard from "Components/NewDashboard/InfoCard/InfoCard";
import { useDispatch } from "react-redux";
import { setCurrentRoute } from "Store/features/currentRoute";
import AddProductModal from "Components/NewDashboard/AddProducts/AddProductModal";
import { useSelector } from "react-redux";
const Products = ({ fromAdmin }) => {
  const [value, setValue] = useState(0);
  const userId = useSelector((state) => state.setUser.user.user_id);
  const [meetingModalOpen, setMeetingModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [products, setProducts] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const [deleteButtonActive, setDeleteButtonActive] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);

  const [allDocs, setAllDocs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [sourceImage, setSourceImage] = useState("");
  const userReducer = useSelector((state) => state.setUser.user);
  const {user_id} = userReducer
  const searchFunction = (docs) => {
    const SearchedRecords = docs.filter((doc) => {
      return doc.document_link
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
    return SearchedRecords;
  };
  const handleUpload = async () => {
    try {
      setUploading(true);
      const user = JSON.parse(sessionStorage.getItem("user"));

      const formData = new FormData();
      formData.append("user_id", user.user_id);
      formData.append("file", selectedFile);

      const response = await axios
        .post(`${baseUrl}add_meeting_document/`, formData)
        .then(async (res) => {
          const response1 = await axios.post(
            `${baseUrl}add_document_medicine_mapping/`,
            { med_id: selectedProduct.med_id, doc_id: res.data.document_id }
          );
        });

      // Handle the response as needed

      // Reset the selected file and close the modal
      setSelectedFile(null);
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };
  const getAllDocs = async (id) => {
    console.log(id);
    const resp = await axios
      .post(`${baseUrl}get_documents_by_medicine/`, {
        med_id: id,
      })
      .then((response) => {
        console.log(response.data);
        setAllDocs(response.data);
      })
      .catch((e) => console.log(e));
  };
  console.log(allDocs);
  const productData = [
    {
      icon: <img src={typeMedicine} />,
      text: "Paracetamol 50 MG",
    },
    {
      icon: <img src={typeMedicine} />,
      text: "Zion Max 400",
    },
    {
      icon: <img src={typeMedicine} />,
      text: "Vita D Sol",
    },
  ];
  const [selectedListItem, setSelectedListItem] = React.useState("");
  const [talkingPoints, setTalkingPoints] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const handleClick = (text, index) => {
    setSelectedListItem(text);
    setSelectedProduct(products[index]);
    setTalkingPoints(products[index].med_dosage_administration);
    console.log(products[index].med_dosage_administration);
  };
  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const payload = {
      document_id: selectedIds,
      // Add other required fields here if needed
    };

    try {
      const resp = await axios.delete(`${baseUrl}delete_document/`, {
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      toast.success("Documents Deleted Successfully !");
      setDeleteModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const assignUserToMedicine = (userId, medId) => {
    const response = assignUsers(userId, medId).then((res) => {
      toast.success("User Assigned");
      getAssigned(medId);
    });
  };
  const assignTeamToMedicine = (team_id, medId) => {
    const response = assignMedtoTeam(team_id, medId).then((res) => {
      toast.success("Team Assigned");
      getAssignedTeam(medId);
    });
  };
  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };
  const getFileExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  function getFileAcceptTypes(selectedIndex) {
    switch (selectedIndex) {
      case 0:
        return "application/pdf,image/jpeg,image/png,image/gif,video/mp4,video/avi,video/quicktime";
      case 1:
        return "application/pdf";
      case 3:
        return "image/jpeg,image/png,image/gif";
      case 2:
        return "video/mp4,video/avi,video/quicktime";
      case 4:
        return "";
      default:
        return "application/pdf";
    }
  }
  const getAllProducts = () => {
    getProducts(user_id)
      .then((res) => {
        setProducts(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [assignedTeams, setAssignedTeams] = useState([]);
  const [unassignedTeams, setUnAssignedTeams] = useState([]);

  const [unassignedUsers, setUnassignedUsers] = useState([]);
  const getAssigned = (id) => {
    const response = getAssignedUsers(id).then((res) => {
      setAssignedUsers(res);
    });
    const response1 = getUnAssignedUsers(id).then((res) => {
      setUnassignedUsers(res);
    });
  };
  const getAssignedTeam = (id) => {
    const response = getAssignedTeams(id).then((res) => {
      setAssignedTeams(res);
    });
    const response1 = getUnAssignedTeams(id).then((res) => {
      setUnAssignedTeams(res);
    });
  };

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    dispatch(setCurrentRoute(2));
    if (user) {
      getAllProducts();
    }
    if (selectedProduct) {
      getAssigned(selectedProduct.med_id);
      getAllDocs(selectedProduct.med_id);
      getAssignedTeam(selectedProduct.med_id);
    }
  }, [modalOpen, deleteModalOpen, selectedIndex, selectedProduct]);
  useEffect(() => {
    setSelectedIds([]);
  }, [selectedIndex]);

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.topLeftContainer}>
          <div className={styles.sectionHeader}>Products</div>
          {fromAdmin && <IconButton onClick={() => setMeetingModalOpen(true)}>
            <AddIcon sx={{ color: "white" }} />
          </IconButton>}
        </div>
        {products.length > 0 ? (
          products.map((data, index) => (
            <div
              onClick={() => handleClick(data.med_name, index)}
              className={
                selectedListItem === data.med_name
                  ? styles.MenuListContainerActive
                  : styles.MenuListContainer
              }
            >
              {data.icon}
              <div className={styles.MenuItem}>{data.med_name}</div>
            </div>
          ))
        ) : (
          <div className={styles.leftEmptyProductsContainer}>
            <img src={noProductIcon} />
            <div className={styles.emptyInfoText}>
              You don’t have any products associated with the team.
            </div>
            {fromAdmin && <button
              onClick={() => setMeetingModalOpen(true)}
              className={styles.addProduct}
            >
              Add Product
            </button>}
          </div>
        )}
      </div>
      <div className={styles.rightContainer}>
        {selectedListItem === "" ? (
          <div className={styles.rightEmptyContainer}>
            Select A product to get started
          </div>
        ) : (
          <>
            <div className={styles.topLeftContainer}>
              <div className={styles.rightSectionHeader}>
                {selectedListItem}
              </div>
            </div>
            {fromAdmin ? (
              <>
                <Tabs
                  value={value}
                  setValue={setValue}
                  firstTab={"Product Template"}
                  thirdTab={"Assign Teams"}
                />
                {value === 0 && (
                  <ProductTemplate
                    isManager={true}
                    fromAdmin={fromAdmin}
                    getAllProducts={getAllProducts}
                    selectedProduct={selectedProduct}
                    productName={selectedListItem}
                    productData={talkingPoints ? talkingPoints : []}
                  />
                )}
                {value === 1 && (
                  <div className={styles.documentsContainer}>
                    <div className={styles.searchContainer}>
                      <SearchField
                        width={"60%"}
                        placeholder="Search Files"
                        value={searchValue}
                        setValue={setSearchValue}
                      />
                      {fromAdmin && <button
                        onClick={() => setModalOpen(true)}
                        className={styles.uploadButton}
                      >
                        <AddIcon /> Upload Documents
                      </button>}
                    </div>
                    <div className={styles.documentsHeader}>
                      {allDocs.length > 0 && allDocs.length} files and documents
                    </div>
                    <div className={styles.cardListContainer}>
                      <DocumentTab
                        
                        setTitle={setTitle}
                        setSourceImage={setSourceImage}
                        isViewerOpen={isViewerOpen}
                        ishome={true}
                        setIsViewerOpen={setIsViewerOpen}
                        type="page"
                        getAllDocs={getAllDocs}
                        selectedIndex={selectedIndex}
                        variant="document"
                        data={
                          searchValue.length > 0
                            ? searchFunction(allDocs)
                            : allDocs
                        }
                        setSelectedIds={setSelectedIds}
                        selectedIds={selectedIds}
                        setDeleteButtonActive={setDeleteButtonActive}
                      />
                    </div>
                  </div>
                )}
                {value === 2 && (
                  <div className={styles.documentsContainer}>
                    <div className={styles.searchContainer}>
                      <SearchField
                        width={"60%"}
                        placeholder="Search teams or add by name"
                        value={searchValue}
                        setValue={setSearchValue}
                      />
                      {/* <button
                    onClick={() => setModalOpen(true)}
                    className={styles.uploadButton}
                  >
                    <AddIcon /> Assign Users
                  </button> */}
                    </div>
                    <div className={styles.documentsHeader}>
                      {assignedTeams.length} Teams Assigned
                    </div>
                    <div className={styles.usersListContainer}>
                      {assignedTeams.length > 0 &&
                        assignedTeams.map((data) => (
                          <div className={styles.userList}>
                            <InfoCard
                              title={`${data.team_name}`}
                              subTitle={`${""}`}
                              icon={"imageString"}
                            />
                            <button
                              style={{ opacity: 0.3 }}
                              disabled={true}
                              className={styles.assignedButton}
                            >
                              Assigned
                            </button>
                          </div>
                        ))}
                      {unassignedTeams.length > 0 &&
                        unassignedTeams.map((data) => (
                          <div className={styles.userList}>
                            <InfoCard
                              title={`${data.team_name}`}
                              subTitle={`${""}`}
                              icon={"Img"}
                            />
                            <button
                              onClick={() =>
                                assignTeamToMedicine(
                                  data.team_id,
                                  selectedProduct.med_id
                                )
                              }
                              className={styles.assignButton}
                            >
                              Assign
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <Tabs
                  value={value}
                  setValue={setValue}
                  fromManager={true}
                  firstTab={"Product Template"}
                  thirdTab={"Assign Users"}
                />
                {value === 0 && (
                  <ProductTemplate
                    isManager={true}
                    getAllProducts={getAllProducts}
                    selectedProduct={selectedProduct}
                    productName={selectedListItem}
                    productData={talkingPoints ? talkingPoints : []}
                  />
                )}
                {value === 1 && (
                  <div className={styles.documentsContainer}>
                    <div className={styles.searchContainer}>
                      <SearchField
                        width={"60%"}
                        placeholder="Search Files"
                        value={searchValue}
                        setValue={setSearchValue}
                      />
                      {fromAdmin &&<button
                        onClick={() => setModalOpen(true)}
                        className={styles.uploadButton}
                      >
                        <AddIcon /> Upload Documents
                      </button>}
                    </div>
                    <div className={styles.documentsHeader}>
                      {allDocs.length > 0 && allDocs.length} files and documents
                    </div>
                    <div className={styles.cardListContainer}>
                      <DocumentTab
                        setTitle={setTitle}
                        setSourceImage={setSourceImage}
                        isViewerOpen={isViewerOpen}
                        ishome={true}
                        setIsViewerOpen={setIsViewerOpen}
                        type="page"
                        getAllDocs={getAllDocs}
                        selectedIndex={selectedIndex}
                        variant="document"
                        data={
                          searchValue.length > 0
                            ? searchFunction(allDocs)
                            : allDocs
                        }
                        setSelectedIds={setSelectedIds}
                        selectedIds={selectedIds}
                        setDeleteButtonActive={setDeleteButtonActive}
                      />
                    </div>
                  </div>
                )}
                {/* {value === 2 && (
                  <div className={styles.documentsContainer}>
                    <div className={styles.searchContainer}>
                      <SearchField
                        width={"60%"}
                        placeholder="Search or add users by name "
                        value={searchValue}
                        setValue={setSearchValue}
                      />
                      <button
                    onClick={() => setModalOpen(true)}
                    className={styles.uploadButton}
                  >
                    <AddIcon /> Assign Users
                  </button> 
                    </div>
                    <div className={styles.documentsHeader}>
                      {assignedUsers.length} Users Assigned
                    </div>
                    <div className={styles.usersListContainer}>
                      {assignedUsers.length > 0 &&
                        assignedUsers.map((data) => (
                          <div className={styles.userList}>
                            <InfoCard
                              title={`${data.first_name} ${data.last_name}`}
                              subTitle={`${data.email}`}
                              icon={data.user_image}
                            />
                            <button
                              style={{ opacity: 0.3 }}
                              disabled={true}
                              className={styles.assignButton}
                            >
                              Assigned
                            </button>
                          </div>
                        ))}
                      {unassignedUsers.length > 0 &&
                        unassignedUsers.map((data) => (
                          <div className={styles.userList}>
                            <InfoCard
                              title={`${data.first_name} ${data.last_name}`}
                              subTitle={`${data.email}`}
                              icon={data.user_image}
                            />
                            <button
                              onClick={() =>
                                assignUserToMedicine(
                                  data.user_id,
                                  selectedProduct.med_id
                                )
                              }
                              className={styles.assignButton}
                            >
                              Assign
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                )} */}
              </>
            )}
          </>
        )}
        {console.log(unassignedUsers)}
      </div>
      {deleteModalOpen && (
        <>
          <DeleteFilesModal
            isOpen={deleteModalOpen}
            onClose={handleCancelDelete}
            onDelete={handleDelete}
            title={`Are you sure you want to delete ${selectedIds.length} ${
              selectedIds.length > 1 ? "files" : "file"
            }?`}
            totalFiles={selectedIds.length}
          />
        </>
      )}
      {isViewerOpen && (
        <div style={{ width: "40%", marginLeft: "30px" }}>
          <ProductSplitView
            title={title}
            isOpen={isViewerOpen}
            setIsOpen={setIsViewerOpen}
            fileExtension={getFileExtension(sourceImage)}
            src_image={sourceImage}
          />
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="upload-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#151515",
            boxShadow: 24,
            p: 4,
            width: 600,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            className={styles.headerText}
            variant="h6"
            id="upload-modal-title"
          >
            Upload Document
          </Typography>
          <Box
            sx={{
              height: 200,
              width: "100%",
              border: "1px dashed #ccc",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 2,
              marginBottom: 2,
              padding: 2,
              cursor: "pointer",
              position: "relative",
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onDrop={handleFileDrop}
          >
            <label htmlFor="file-upload-input">
              <input
                id="file-upload-input"
                type="file"
                accept={getFileAcceptTypes(selectedIndex)} // Specify the accepted file types if necessary
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <AddCircleOutlineIcon
                sx={{
                  ml: 10,
                  fontSize: 60,
                  position: "absolute",
                  color: "#ffffff",
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  paddingTop: "50px",
                  color: "#ffffff",
                }}
              >
                Drag a file here or click to browse
              </Typography>
            </label>
          </Box>
          {selectedFile && (
            <Typography variant="h6" sx={{ margin: 2, color: "#ffffff" }}>
              Selected file: {selectedFile.name}
            </Typography>
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Button
              onClick={() => {
                setModalOpen(false);
                setSelectedFile(null);
              }}
              sx={{
                cursor: "pointer",
                color: "#ffffff",
                border: "1px solid #fff",
                padding: "9px 16px 9px 16px",
                height: "40px",
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              Close
            </Button>
            <Button
              onClick={handleUpload}
              disabled={!selectedFile || uploading}
              className={styles.settingsButton}
              sx={{
                ":hover": {
                  backgroundColor: "#ffffff",
                },
                backgroundColor: "#ffffff",
                color: "black !important",
                borderRadius: "4px",
                border: "1px solid #E9E9E9",
                height: "40px",
                padding: "9px 16px 9px 16px",
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              Upload
            </Button>
          </div>
        </Box>
      </Modal>
      <AddProductModal
        id={user_id}
        meetingModalOpen={meetingModalOpen}
        setMeetingModalOpen={setMeetingModalOpen}
      />
    </div>
  );
};

export default Products;
