import React from "react";
import Box from "@mui/material/Box";
import styles from "./Library.module.css";
import { Stack, Typography } from "@mui/material";

const MenuList = ({ list, selected, setSelected, setSelectedIndex }) => {
  const [selectedItem, setSelectedItem] = React.useState(list[0].text);

  return (
    <>
      {list.map((item, index) => (
        <>
          {item.text == selectedItem ? (
            <>
              <Box key={index} className={styles.listItemSelected}>
                <Stack direction="row" alignItems={"center"}>
                  <img src={item.icon} alt="icon" />
                  <Typography>{item.text}</Typography>
                </Stack>
              </Box>
            </>
          ) : (
            <>
              <Box
                className={styles.listItem}
                onClick={() => {
                  setSelectedItem(item.text);
                  setSelected(item.text);
                  setSelectedIndex(index);
                }}
              >
                <Stack direction="row" alignItems={"center"}>
                  <img src={item.icon} alt="icon" />
                  <Typography>{item.text}</Typography>
                </Stack>
              </Box>
            </>
          )}
        </>
      ))}
    </>
  );
};

export default MenuList;
