import React, { useState, useEffect } from "react";
import styles from "./users.module.css";
import AddIcon from "@mui/icons-material/Add";
import UserCard from "Pages/ManagersFlow/Components/UserCard/UserCard";
import {
  Typography,
  Card,
  Button,
  Grid,
  Box,
  CircularProgress,
  Stack,
  Pagination,
  PaginationItem,
} from "@mui/material";
import SearchField from "Components/Common/SearchField/SearchField";
import GroupsIcon from "@mui/icons-material/Groups";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UserHead from "../../Components/users/UserHead";
import CardUser from "../../Components/users/UserCard";
import Modal from "../../Components/Modal";
import AddUserForm from "Pages/AdminFlow/Components/users/AddUserForm";
import { toast } from "react-toastify";
import { userEmailSignup, getAllUser, getTeamUser, deleteUserFromTeam } from "Api/Backend/backend";
import firebase from "firebase/compat/app";
import { useSelector } from "react-redux";
import UserModal from "Pages/AdminFlow/Components/teams/UserSetting/UserModal";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Users = ({ fromTeam, team_id, fromManager,leftPanel }) => {
  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const itemsPerPage = 10;
  const [open, setOpen] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [data, setData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const userReducer = useSelector((state) => state.setUser.user);
  const currentUser = useSelector((state) => state.setUser.user);
  const [searchTerm, setSearchTerm] = useState("");
  const { user_id } = userReducer;

  const handleGetAllUser = () => {
    setUserLoader(true);
    getAllUser(user_id)
      .then((res) => {
        setUserLoader(false);
        setData(res.data);
      })
      .catch((err) => {
        setUserLoader(false);
        console.error("Error fetching all users:", err);
      });
  };

  const handleGetAllUserTeam = () => {
    if (fromTeam) {
      setUserLoader(true);
      getTeamUser(team_id)
        .then((res) => {
          console.log(res);
          setUserLoader(false);
          setTeamData(res.data);
        })
        .catch((err) => {
          setUserLoader(false);
          console.error("Error fetching team users:", err);
        });
    }
  };

  useEffect(() => {
    handleGetAllUser();
    handleGetAllUserTeam();
  }, [open, fetch]);

  const handleDelete =(id)=>{
    setFetch(false);
    deleteUserFromTeam(id)
    .then((res) => {
      setFetch(true);
      toast.success("User Removed")
    })
    .catch((err) => {
      console.error("Error:", err);
    });

  }

  const handleSignUpWithEmailPassword = async (email, password) => {
    try {
      const userExists = await firebase
        .auth()
        .fetchProvidersForEmail(email)
        .then((providers) => {
          if (providers.length === 0) {
            return false;
          } else {
            return true;
          }
        });
      if (!userExists) {
        const userCredential = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        const user = userCredential.user;
        user.sendEmailVerification();
        return user;
      }
    } catch (error) {
      toast.error("already exists");
    }
  };

  const handleAddUser = async (payload) => {
    const { email, password } = payload;
    setLoader(true);
    handleSignUpWithEmailPassword(email, password)
      .then((resp) => {
        const data = resp._delegate;
        Object.assign(payload, {
          firebase_id: data.uid,
          organization_name: "orgName",
          organization_type: "Pharmaceutical Sales",
          first_time_user: true,
          user_image: "",
        });
        userEmailSignup(payload)
          .then((res) => {
            setLoader(false);
            toast.success("User added successfully !");
            setOpen(false);
            handleGetAllUser();
          })
          .catch((res) => {
            setLoader(false);
            toast.error("Sorry, somnething went wrong !");
            setOpen(false);
          });
      })
      .catch(() => {
        toast.error("User already exists");
        setLoader(false);
        setOpen(false);
      });
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const adjustedCurrentPage = currentPage - 1;

  

  return (
    <>
      <div className={leftPanel ? "" : styles.container}>
        {!leftPanel && (
          <div className={styles.wrapper1}>
            <UserCard
              title={`${currentUser.first_name} ${currentUser.last_name}`}
              subTitle={`${currentUser.role
                .charAt(0)
                .toUpperCase()}${currentUser.role.slice(1).toLowerCase()}`}
              icon={currentUser.user_image}
            />
          </div>
        )}
        <div className={leftPanel ? styles.teamwrapper : styles.wrapper2}>
          {/* <Box style={{width:"100%", display:"flex"}}> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className={styles.title}>Manage Users</Typography>
            {fromManager ? (
              <></>
            ) : (
              <button className={styles.btn} onClick={() => setOpen(true)}>
                {" "}
                <AddIcon
                  className={styles.plusIcon}
                  sx={{ height: "20px", width: "20px" }}
                />
                Add User
              </button>
            )}
          </Box>
          <Box className={styles.tabsContainer}>
            <div className={styles.tabsWrapper}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  borderBottom: "1px solid white",
                }}
              >
                <GroupsIcon
                  style={{
                    color: "white",
                    marginRight: "10px",
                    fontSize: "25px",
                  }}
                />
                <Typography
                  className={styles.subTitle}
                  style={{ color: "red" }}
                >
                  All Users
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "10px" }}
              >
                <MailOutlineIcon
                  style={{
                    color: "#141415",
                    marginRight: "10px",
                    fontSize: "25px",
                  }}
                />
                <Typography className={styles.subTitle}>Invited</Typography>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "10px" }}
              >
                <DeleteOutlineIcon
                  style={{
                    color: "#141415",
                    marginRight: "10px",
                    fontSize: "25px",
                  }}
                />
                <Typography className={styles.subTitle}>Archived</Typography>
              </Box>
            </div>
            {/* <div style={{width:"60%"}}> */}
            <SearchField
              width={"40%"}
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder="Search Users"
            />
          </Box>
          <Box>
            <UserHead />
            {userLoader && (
              <CircularProgress size={36} style={{ marginTop: "30px" }} />
            )}
            {!userLoader && data && data.length === 0 && (
              <Typography
                style={{ fontSize: "20px", marginTop: "30px", color: "white" }}
              >
                No User available !
              </Typography>
            )}
            {!userLoader &&
              !fromTeam &&
              data &&
              data.length > 0 &&
              data
                .filter((item) =>
                  `${item.first_name} ${item.last_name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .slice(
                  adjustedCurrentPage * itemsPerPage,
                  Math.min((adjustedCurrentPage + 1) * itemsPerPage, data.length)
                )
                .map((item, index) => (
                  <CardUser
                    fromTeam={false}
                    fromManager={fromManager}
                    setFetch={setFetch}
                    loader={loader}
                    data={item}
                    index={index}
                  />
                ))}
            {!userLoader &&
              teamData &&
              teamData.length > 0 &&
              teamData
                .filter((item) =>
                  `${item.first_name} ${item.last_name}`
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .slice(
                  adjustedCurrentPage * itemsPerPage,
                  Math.min((adjustedCurrentPage + 1) * itemsPerPage, teamData.length)
                )
                .map((item, index) => (
                  <CardUser
                    handleDeleteUser={handleDelete}
                    fromTeam={true}
                    loader={loader}
                    data={item}
                    leftPanel={leftPanel}
                    index={index}
                    key={index}
                  />
                ))}
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Pagination
                shape="rounded"
                count={fromTeam ? Math.ceil(teamData.length / itemsPerPage):Math.ceil(data.length / itemsPerPage)}
                page={currentPage}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      previous: NavigateBeforeIcon,
                      next: NavigateNextIcon,
                    }}
                    {...item}
                  />
                )}
                onChange={handlePageChange}
                color="grey"
                sx={{
                  "& .MuiPaginationItem-page": {
                    color: "white",
                  },
                  "& .MuiPaginationItem-icon": {
                    color: "white",
                  },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    color: "white",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                  "& .MuiPaginationItem-page:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              />
            </Stack>
          </Box>
        </div>
      </div>
      <Modal open={open} title="Add User" isClose={() => setOpen(false)}>
        {fromTeam ? (
          <UserModal
            team_id={team_id}
            setOpen={setOpen}
            users={data}
            teamData={teamData}
          />
        ) : (
          <AddUserForm
            isClose={() => setOpen(false)}
            onSubmit={handleAddUser}
            editUser={false}
            loader={loader}
          />
        )}
      </Modal>
    </>
  );
};

export default Users;
