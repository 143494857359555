import React from 'react'
import styles from "../Home/home.module.css"

const Company = () => {
  return (
    <div className={styles.container}>
       <h1 style={{color:"white"}}>Company</h1>
    </div>
  )
}

export default Company