import React, { useEffect, useState } from 'react'
import UserCard from "Pages/ManagersFlow/Components/UserCard/UserCard";
import styles from "./home.module.css"
import { Typography , Card, Button, Grid} from '@mui/material';
import { getAdminStats } from 'Api/Backend/backend';

const Home = () => {

  const [stats,setStats]= useState({})

  const handleGetAllStats =()=>{
    getAdminStats()
    .then((res) => {
      
      setStats(res.data);
    })
    .catch((err) => {
      console.error("Error in fetching:", err);
    });

  }

  useEffect(() => {
    handleGetAllStats();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.wrapper1}>
        <UserCard
          title={`Admin`}
          subTitle={"Super Admin Account"}
          // icon={currentUser.user_image}
        />
      </div>
      <div className={styles.wrapper2}>
        <div style={{width:"60%"}}>
      <Typography className={styles.title}>Update</Typography>
      <Card className={styles.card}>
        <Typography className={styles.subTitle}>
          Welcome to the admin accont
        </Typography>
        <Typography className={styles.text}>
          Everything is working well
        </Typography>
      </Card>
      <div style={{marginTop:"25px"}}>
      <Typography className={styles.title}>Overview</Typography>
      <Card className={styles.card}>
        <Typography className={styles.subTitle}>
          Biopharmatech inc
        </Typography>
        <Typography className={styles.text}>
        Pharmaceutical Sales
        </Typography>
        <Button variant='outlined' className={styles.btn}>
        Edit Details 
        </Button>
      </Card>
      </div>
      {stats && <Grid container spacing={3}>
        <Grid item md={4}>
        <Card className={styles.card}>
        <Typography className={styles.text}>
        Total Users
        </Typography>
        <Typography className={styles.count}>
          {stats.users}
        </Typography>
      </Card>
        </Grid>
        <Grid item md={4}>
        <Card className={styles.card}>
        <Typography className={styles.text}>
        Total Teams
        </Typography>
        <Typography className={styles.count}>
          {stats.teams}
        </Typography>
      </Card>
        </Grid>
        <Grid item md={4}>
        <Card className={styles.card}>
        <Typography className={styles.text}>
        Total Products
        </Typography>
        <Typography className={styles.count}>
          {stats.products}
        </Typography>
      </Card>
        </Grid>
      </Grid>}
      </div>
      </div>
    </div>
  )
}

export default Home