import React, { useEffect, useState } from "react";
import styles from "./AccountInfo.module.css";
import InputField from "Components/Common/InputField/InputField";
import InfoBlock from "Components/Common/InfoBlock/InfoBlock";
import { Box, Button, Divider, Grid } from "@mui/material";
import UserIcon from "./Assets/AccountIcon.svg";
import EmailIcon from "./Assets/MailIcon.svg";
import styles1 from "./AccountInfo.module.css";
import BuildingIcon from "../../../Assets/buildingIcon.svg";
import DoubleUserIcon from "../../../Assets/doubleUserIcon.svg";
import BriefCaseIcon from "./Assets/BriefCaseIcon.svg";
import BriefcaseIcon from "./Assets/BriefCaseIcon.svg";
import { updateUserProfile } from "Api/Backend/backend";
import { toast } from "react-toastify";
import { setUser } from "Store/features/setUserSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import { baseUrl } from "Api/Backend/backend";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import organisationIcon from "./Assets/organisationIcon.svg";

const AccountInfo = ({ isEditMode, setEditMode, userData, setData }) => {
  const [editData, setEditData] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [organizationName, setOrganizationName] = useState(
    editData.organization_name
  );
  const [organizationType, setOrganizationType] = useState(
    editData.organization_type
  );
  const [region, setRegion] = useState(editData?.team_name);
  const user_data = JSON.parse(sessionStorage.getItem("user"));
  const userId = user_data.user_id;
  const dispatch = useDispatch();
  useEffect(() => {
    if (editData.length === 0) {
      setEditData(userData);
    }
  }, []);

  function separateNames(fullName) {
    const namesArray = fullName.split(" ");
    const firstName = namesArray[0];
    const lastName = namesArray.slice(1).join(" ");

    return {
      first_name: firstName,
      last_name: lastName,
    };
  }
  const updateUser = () => {
    const sepName = separateNames(name);
    const first_name = sepName.first_name;
    const last_name = sepName.last_name;

    const postData = {
      user_id: userId,
      first_name: first_name,
      last_name: last_name,
      role: role,
      organization_name: organizationName,
      organization_type: organizationType,
      team_name: region,
    };
    const response = updateUserProfile(postData)
      .then(async (res) => {
        toast.success("Profile Updated");
        setEditMode(false);
        const uid = sessionStorage.getItem("firebase");
        const getUser = await axios
          .post(
            `${baseUrl}get_user_by_firebaseid/?firebase_id=${uid}`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((response) => {
            if (response.data) {
              dispatch(setUser(response.data));
              sessionStorage.setItem("user", JSON.stringify(response.data));
            }
          })
          .catch((e) => {
            console.log(e);
          });
        setData(postData);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    setName(editData.name);
    setRole(editData.role);
    setOrganizationName(editData.organization_name);
    setOrganizationType(editData.organization_type);
    setRegion(editData.team_name);
  }, [editData]);
  return (
    <>
      <Grid item className={styles.itemContainer}>
        <Box className={styles.buttonContainer}>
          <div className={styles.headingText}>Account Settings</div>
          <Button
            onClick={() => setEditMode(true)}
            className={
              isEditMode ? styles.submitButtonDisabled : styles.editButton
            }
            disabled={isEditMode ? true : false}
          >
            Edit Profile
          </Button>
          <Button
            onClick={updateUser}
            className={
              isEditMode ? styles.submitButton : styles.submitButtonDisabled
            }
            disabled={isEditMode ? false : true}
          >
            Save Profile
          </Button>
        </Box>
      </Grid>
      <Grid container className={styles.settingsGrid}>
        <Grid xs={6} className={styles.gridItem}>
          <InfoBlock
            heading="Account info"
            description="You can update your account information here except your work email."
            headingFontSize={20}
            headingFontWeight={700}
          />
        </Grid>

        <Grid xs={6} spacing={8} className={styles.gridItem}>
          <>
            {" "}
            <Grid item className={styles.item}>
              <InputField
                value={`${name}`}
                editable={isEditMode}
                startIcon={UserIcon}
                setter={setName}
              />
            </Grid>
            <Grid item className={styles.item}>
              <InputField
                value={editData.email}
                editable={false}
                startIcon={EmailIcon}
              />
            </Grid>
            <Grid item className={styles.item}>
              <InputField
                value={role}
                editable={false}
                startIcon={BriefCaseIcon}
                setter={setRole}
              />
            </Grid>
          </>
        </Grid>
      </Grid>
      <>
        <Divider />
        <Box sx={{ mt: 5 }}></Box>
        <Grid container className={styles1.settingsGrid}>
          <Grid xs={6} className={styles1.gridItem}>
            <InfoBlock
              heading="Organization"
              description="Manage your notification settings with just one click."
              headingFontSize={20}
              headingFontWeight={700}
            />
          </Grid>
          <Grid xs={6} spacing={8} className={styles1.gridItem}>
            <Grid item className={styles1.item}>
              <div className={styles1.organisationContainer}>
                <img src={organisationIcon} alt="icon" />
                <div className={styles.organizationInnerContainer}>
                  <div className={styles.organizationName}>
                    AMGEN Biotechnologies
                  </div>
                  <div className={styles.subtitle}>Pharmaceutical Sales</div>
                </div>
                <ModeEditIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                    opacity: 0.5,
                  }}
                />
              </div>
            </Grid>
            {/* <Grid item className={styles1.item}>
              <InputField
                value={organizationName}
                editable={isEditMode}
                setter={setOrganizationName}
                startIcon={BriefcaseIcon}
              />
            </Grid>
            <Grid item className={styles1.item}>
              <InputField
                value={organizationType}
                editable={isEditMode}
                setter={setOrganizationType}
                startIcon={BriefcaseIcon}
              />
            </Grid>
            <Grid item className={styles1.item}>
              <InputField
                value={region}
                editable={isEditMode}
                setter={setRegion}
                startIcon={BriefcaseIcon}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default AccountInfo;
